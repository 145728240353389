<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">아웃바운드 관리</h1>
    <div class="box-wrap d-flex">
      <div class="box-ct">
        <div>
          <div class="form-inp sm">
            <v-select
              v-model.trim="searchData.ASP_NEWCUST_KEY"
              :items="geUserCompany"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              placeholder="선택"
            >
            <template v-slot:label>
              회사구분
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
          </v-select>
          </div>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal "
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="autoSearchDateFormat"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
                label="조회일자"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model.trim="searchData.START_OBND_DATE"
                no-title
                dark
                color="#F7AFAE"
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
                :max="searchData.END_OBND_DATE"
              ></v-date-picker>
              <v-date-picker
                v-model.trim="searchData.END_OBND_DATE"
                dark
                color="#F7AFAE"
                no-title
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
                :min="searchData.START_OBND_DATE"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn outlined class="btn-default" @click="menu1=false">확인</v-btn>
            </div>
          </v-menu>
          <v-text-field
            v-model.trim="searchData.SRCH_CAM_NM"
            class="form-inp ml-2"
            label="아웃바운드명"
            outlined
            hide-details
            @keyup.enter="selectRtn"
          >
          </v-text-field>
          <div class="form-inp sm ml-2">
            <v-select
              v-model.trim="searchData.SRCH_CAM_TY"
              :items="mixin_common_code_get(this.commonCodeItems, 'RE010', '전체')"
              outlined
              hide-details
              label="캠페인구분"
            >
          </v-select>
          </div>
          <div class="form-inp sm  ml-2">
            <v-select
              v-model.trim="searchData.SRCH_DSTR_YN"
              :items="dstrYnItems"
              outlined
              hide-details
              label="배분구분"
            >
          </v-select>
          </div>
          <div class="form-inp sm  ml-2">
            <v-select
              v-model.trim="searchData.SRCH_BUSI_TY"
              :items="mixin_common_code_get(this.commonCodeItems, 'OU002', '전체')"
              outlined
              hide-details
              label="업무구분"
            >
          </v-select>
          </div>
          <div class="form-inp sm  ml-2">
            <v-select
              v-model.trim="searchData.SRCH_FISH_YN"
              :items="fishYnItems"
              outlined
              hide-details
              label="완료구분"
            >
          </v-select>
          </div>
        </div>
      </div>
      <div class="ml-auto align-self-center">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" id="btnSearch" @click="selectRtn">조회</v-btn>
      </div>
    </div>
    <!-- 아웃바운드 리스트 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">아웃바운드 리스트
        <div class="ml-auto align-self-center mr-0">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPopupAttend')" outlined class="btn-default" id="btnObndDilcePop" @click="openPopup('M110402P01')">
            사용자근태
          </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPopupDist')" outlined class="btn-default ml-2" id="btnObndDivPop" @click="openPopup('M110402P02')">
            사용자배분
          </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown1')" outlined class="btn-point ml-2" id="btnObndExcel" @click="excelDownload('divGridObndList', '아웃바운드 리스트')">
            엑셀 다운로드
          </v-btn>
        </div>
        <!-- 사용자근태 모달 -->
        <v-dialog max-width="1400" persistent v-if="dialogM110402P01" v-model="dialogM110402P01" content-class="square-modal">
          <dialog-M110402P01
            ref="dialogM110402P01ref"
            headerTitle="사용자근태"
            :params=dialogParamP01
            @hide="hideDialog('M110402P01')"
            @submit="submitDialog('M110402P01')"
          >
          </dialog-M110402P01>
        </v-dialog>

        <!-- 사용자배분 모달 -->
        <v-dialog max-width="1400" persistent v-if="dialogM110402P02" v-model="dialogM110402P02" content-class="square-modal">
          <dialog-M110402P02
            headerTitle="아웃바운드 사용자 배분"
            :param=dialogParamP02
            @hide="hideDialog('M110402P02')"
            @submit="submitDialog('M110402P02')"
            @add="addDialog('M110402P02')"
          >
          </dialog-M110402P02>
        </v-dialog>
      </h2>
      <div class="box-ct">
        <v-data-table
          id="divGridObndList"
          v-model="gridObndCustList"
          :headers="gridObndCustHeaders"
          :items="gridObndCustItems"
          item-key="ROW_NUMBER"
          fixed-header
          hide-default-footer
          show-select
          dense
          height="170px"
          class="grid-default"
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="30"
          @click:row="gridRowClick"
          @dblclick:row="openPopup('M110402P02', 'dblClick')"
          :item-class="gridRowActive"
        >
        </v-data-table>
      <div class="grid-paging text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="totalVisible"
          next-icon="svg-paging-next"
          prev-icon="svg-paging-prev"
        ></v-pagination>
      </div>
      <p class="caption text-left">
        총 <strong class="clr-error">{{ gridObndCustItems.length | inputNumberFormat }}</strong>건
      </p>
      </div>
    </div>

    <!-- 아웃바운드 정보 -->
    <v-form ref="form" v-model="isFormValid" lazy-validation>
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">아웃바운드 상세정보
        <div class="ml-auto align-self-center mr-0">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSaveOne')" outlined class="btn-default" @click="openPopup('M110402P03')">
            고객단건등록
          </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnEnd')" outlined class="btn-point ml-2" id="btnEnd" @click="processRtn('END')">
            {{ btnEndText }}
          </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" id="btnDel" @click="processRtn('DEL')">
            삭제
          </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnMody')" outlined class="btn-point ml-2" id="btnUpdate" @click="processRtn('UPDT')">
            수정
          </v-btn>
        </div>
        <!-- 고객단건등록 모달 -->
        <v-dialog max-width="1400" persistent v-model="dialogM110402P03" v-if="dialogM110402P03" content-class="square-modal" id="btnSngle">
          <dialog-M110402P03
            headerTitle="아웃바운드 단건 등록"
            :p03Param=dialogParamP03
            @hide="hideDialog('M110402P03')"
            @submit="submitDialog('M110402P03')"
          >
          </dialog-M110402P03>
        </v-dialog>
      </h2>
      <div class="box-ct d-flex">
        <div class="table-form col-6 pa-0">
          <table>
            <caption class="hide">
              항목
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  회사구분
                </th>
                <td >
                  <div class="form-inp sm full">
                    <v-select
                      v-model.trim="formData.ASP_NEWCUST_KEY_DTAIL"
                      :items="geUserCompany"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      disabled
                      placeholder="선택"
                    >
                    </v-select>
                  </div>
                </td>
                <th scope="row">
                  캠페인구분
                </th>
                <td >
                  <div class="form-inp sm full">
                    <v-text-field
                      v-model.trim="formData.CAM_TY_NM"
                      class="form-inp sm full"
                      outlined
                      hide-details=""
                      disabled
                    >
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  아웃바운드명
                </th>
                <td>
                  <v-text-field
                    v-model.trim="formData.CAM_NM"
                    class="form-inp sm full"
                    outlined
                    hide-details=""
                    :disabled="btnEndText==='마감취소'"
                  >
                  </v-text-field>
                </td>
                <th scope="row">
                  업무구분
                </th>
                <td>
                  <v-text-field
                    v-model.trim="formData.BUSI_TY_NM"
                    class="form-inp sm full"
                    outlined
                    hide-details=""
                    disabled
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row" rowspan="3">
                  비고
                </th>
                <td rowspan="3">
                  <v-textarea
                    v-model.trim="formData.REM"
                    hide-details="auto"
                    no-resize
                    outlined
                    height="120"
                    :rules="textareaRules"
                    :disabled="btnEndText==='마감취소'"
                  ></v-textarea>
                </td>
                <th scope="row">
                  완료구분
                </th>
                <td>
                  <v-text-field
                    v-model.trim="formData.FISH_YN_NM"
                    class="form-inp sm full"
                    outlined
                    hide-details=""
                    disabled
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  시작일시
                </th>
                <td>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    color="#FFF"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="autoBeginDateFormat"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        readonly
                        hide-details="auto"
                        class="form-inp icon-calendar"
                        append-icon="svg-calendar"
                        maxlength="19"
                        :disabled="btnEndText==='마감취소'"
                      ></v-text-field>
                      <v-btn v-if="mixin_set_btn($options.name, 'btnCancleFin1')" dark small class="btn-main-refresh pa-0 ml-2" min-width="31px" height="31px" plain
                        @click="formData.CAM_BEGIN_DATE='';formData.CAM_BEGIN_TIME=''"
                        :disabled="btnEndText==='마감취소'"
                      >
                        <v-icon small class="svg-main-refresh"></v-icon>
                      </v-btn>
                    </template>
                    <div class="d-flex align-center">
                      <v-date-picker
                        v-model.trim="formData.CAM_BEGIN_DATE"
                        dark
                        color="#F7AFAE"
                        no-title
                        locale="ko-KR"
                        :first-day-of-week="1"
                        :day-format="mixin_getDate"
                        :max="formData.CAM_EOT_DATE"
                        :disabled="btnEndText==='마감취소'"
                      ></v-date-picker>
                      <v-time-picker
                        v-model.trim="formData.CAM_BEGIN_TIME"
                        color="#F7AFAE"
                        format="24hr"
                        @click:minute="$refs.menu2.save()"
                        :disabled="btnEndText==='마감취소'"
                      ></v-time-picker>
                    </div>
                  </v-menu>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  종료일시
                </th>
                <td>
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    color="#FFF"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="autoEotDateFormat"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        readonly
                        hide-details="auto"
                        class="form-inp icon-calendar"
                        append-icon="svg-calendar"
                        maxlength="19"
                        :disabled="btnEndText==='마감취소'"
                      ></v-text-field>
                      <v-btn v-if="mixin_set_btn($options.name, 'btnCancleFin2')" dark small class="btn-main-refresh pa-0 ml-2" min-width="31px" height="31px" plain
                        @click="formData.CAM_EOT_DATE='';formData.CAM_EOT_TIME=''"
                        :disabled="btnEndText==='마감취소'"
                      >
                        <v-icon small class="svg-main-refresh"></v-icon>
                      </v-btn>
                    </template>
                    <div class="d-flex align-center">
                      <v-date-picker
                        v-model.trim="formData.CAM_EOT_DATE"
                        dark
                        color="#F7AFAE"
                        no-title
                        locale="ko-KR"
                        :first-day-of-week="1"
                        :day-format="mixin_getDate"
                        :min="formData.CAM_BEGIN_DATE"
                        :disabled="btnEndText==='마감취소'"
                      ></v-date-picker>
                      <v-time-picker
                        v-model.trim="formData.CAM_EOT_TIME"
                        color="#F7AFAE"
                        format="24hr"
                        @click:minute="$refs.menu3.save()"
                        :disabled="btnEndText==='마감취소'"
                      ></v-time-picker>
                    </div>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-form col-6 pa-0 ml-2">
          <table>
            <caption class="hide">
              항목
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="" />
              <col width="127px" />
              <col width="" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row"> 최종시도실패건수 </th>
                <td > {{ formData.FAIL_CNT | inputNumberFormat }} </td>
                <th scope="row"> 미시도건수 </th>
                <td > {{ formData.NOT_END_CNT | inputNumberFormat }} </td>
                <th scope="row"> 처리불가건수 </th>
                <td > {{ formData.CAN_NOT_CNT | inputNumberFormat }} </td>
              </tr>
              <tr>
                <th scope="row"> 처리중건수 </th>
                <td > {{ formData.ING_CNT | inputNumberFormat }} </td>
                <th scope="row"> 처리완료건수 </th>
                <td > {{ formData.END_CNT | inputNumberFormat }} </td>
                <th scope="row"> 처리완료_완판 </th>
                <td > {{ formData.EDPF_CNT | inputNumberFormat }} </td>
              </tr>
              <tr>
                <th scope="row"> 처리완료_불판 </th>
                <td > {{ formData.EDFA_CNT | inputNumberFormat }} </td>
                <th scope="row"> 처리완료_10회 </th>
                <td > {{ formData.EDTN_CNT | inputNumberFormat }} </td>
                <th scope="row"> 처리완료_안내장 </th>
                <td > {{ formData.EDSN_CNT | inputNumberFormat }} </td>
              </tr>
              <tr>
                <th scope="row"> 기타건수 </th>
                <td > {{ formData.ETC_CNT | inputNumberFormat }} </td>
                <th scope="row"> 배분건수 </th>
                <td > {{ formData.DIV_CNT | inputNumberFormat }} </td>
                <th scope="row"> 미배분건수 </th>
                <td > {{ formData.NON_DIV_CNT | inputNumberFormat }} </td>
              </tr>
              <tr>
                <th scope="row"> 총건수 </th>
                <td > {{ formData.TOT_CNT | inputNumberFormat }} </td>
                <th scope="row"></th>
                <td > </td>
                <th scope="row"> </th>
                <td > </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- 아웃바운드 배분정보 -->
      <h2 class="tit-h2 d-flex mt-3">아웃바운드 배분정보
        <div class="ml-auto align-self-center mr-0">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown2')" outlined class="btn-point" id="btnObndDivExcel" @click="excelDownload('divGridObndDivInfo', '아웃바운드 배분정보 리스트')">
            엑셀 다운로드
          </v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
          id="divGridObndDivInfo"
          v-model="gridObndDivList"
          :headers="gridObndDivHeaders"
          :items="gridObndDivItems"
          item-key="ROW_NUMBER"
          fixed-header
          hide-default-footer
          dense
          height="170px"
          class="grid-default"
          :page.sync="page2"
          @page-count="pageCount2 = $event"
          :items-per-page="30"
        >
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page2"
            :length="pageCount2"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
        <p class="caption text-left">
          총 <strong class="clr-error">{{ gridObndDivItems.length | inputNumberFormat }}</strong>건
        </p>
      </div>
    </div>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { mixin } from "@/mixin/mixin.js"
import DialogM110402P01 from "./M110402P01.vue"
import DialogM110402P02 from "./M110402P02.vue"
import DialogM110402P03 from "./M110402P03.vue"
// import DialogM110402P05 from "@/views/page/M110402P05.vue"

export default {
  name: "MENU_M110402", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogM110402P01,
    DialogM110402P02,
    DialogM110402P03,
    // DialogM110402P05,
  },
  data() {
    return {
      dialogM110402P01: false, //사용자근태
      dialogM110402P02: false, //사용자배분
      dialogM110402P03: false, //고객단건등록
      // dialogM110402P05: false, //고객단건등록22
      menu1: false,
      menu2: false,
      menu3: false,
      btnEndText: '마감',
      commonCodeItems: [],
      dstrYnItems: [ {'text':'전체','value':''},{'text':'배분','value':'Y'},{'text':'미배분','value':'N'} ],
      fishYnItems: [ {'text':'전체','value':''},{'text':'완료','value':'Y'},{'text':'미완료','value':'N'} ],
      gridObndCustList: [],
      gridObndCustItems: [],
      gridObndCustHeaders: [
        { text: 'NO', value: 'ROW_NUMBER', align: 'center', width: '70px', sortable: true },
        { text: 'SELECT', value: 'data-table-select', align: 'center', width: '20px'},
        { text: '회사명', value: 'ASP_NEWCUST_KEY', align: 'center', width: '200px', sortable: true },
        // { text: '아웃바운드ID', value: 'CAM_ID', align: 'center', width: '70px', sortable: false },
        { text: '아웃바운드명', value: 'CAM_NM', align: 'left', width: '300px', sortable: true },
        // { text: '캠페인구분코드', value: 'CAM_TY', align: 'center', width: '0px', sortable: false },
        { text: '캠페인구분', value: 'CAM_TY_NM', align: 'left', width: '200px', sortable: true },
        // { text: '업무구분코드', value: 'BUSI_TY', align: 'center', width: '0px', sortable: false },
        { text: '업무구분', value: 'BUSI_TY_NM', align: 'center', width: '90px', sortable: true },
        // { text: '완료구분코드', value: 'FISH_YN', align: 'center', width: '0px', sortable: false },
        { text: '완료구분', value: 'FISH_YN_NM', align: 'center', width: '90px', sortable: true },
        { text: '시작일자', value: 'CAM_BEGIN_DATE', align: 'center', width: '100px', sortable: true },
        { text: '시작시간', value: 'CAM_BEGIN_TIME', align: 'center', width: '100px', sortable: true },
        { text: '종료일자', value: 'CAM_EOT_DATE', align: 'center', width: '100px', sortable: true },
        { text: '종료시간', value: 'CAM_EOT_TIME', align: 'center', width: '100px', sortable: true }
      ],
      gridObndDivList: [],
      gridObndDivItems: [],
      gridObndDivHeaders: [
        // { text: '상담원사번', value: 'CSLT_ID', align: 'center', width: '40px', sortable: true },
        { text: 'NO', value: 'ROW_NUMBER', align: 'center', width: '70px', sortable: true },
        { text: '상담원명', value: 'USER_NM', align: 'center', width: '150px', sortable: true },
        { text: '배분건수', value: 'DIV_CNT', align: 'center', width: '100px', sortable: true },
        { text: '미시도건수', value: 'NOT_END_CNT', align: 'center', width: '110px', sortable: true },
        { text: '처리완료건수', value: 'END_CNT', align: 'center', width: '150px', sortable: true },
        { text: '처리완료_완판', value: 'EDPF_CNT', align: 'center', width: '150px', sortable: true },
        { text: '처리완료_불판', value: 'EDFA_CNT', align: 'center', width: '150px', sortable: true },
        { text: '처리완료_10회시도', value: 'EDTN_CNT', align: 'center', width: '160px', sortable: true },
        { text: '처리완료_안내장', value: 'EDSN_CNT', align: 'center', width: '160px', sortable: true },
        { text: '처리불가건수', value: 'CAN_NOT_CNT', align: 'center', width: '160px', sortable: true },
        { text: '처리중건수', value: 'ING_CNT', align: 'center', width: '130px', sortable: true },
        { text: '최종시도실패건수', value: 'FAIL_CNT', align: 'center', width: '160px', sortable: true },
        // { text: '기타건수', value: 'ETC_CNT', align: 'center', width: '40px', sortable: true }
      ],
      page: 1,
      page2: 1,
      pageCount: 0,
      pageCount2: 0,
      itemsPerPage: 50,
      totalVisible: 10,
      searchVo: function() {
        this.P_MENU_NM='ObndMng'
        this.ASP_NEWCUST_KEY=''
        this.START_OBND_DATE=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.END_OBND_DATE=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.SRCH_CAM_TY=''
        this.SRCH_DSTR_YN=''
        this.SRCH_CAM_NM=''
        this.SRCH_BUSI_TY=''
        this.SRCH_FISH_YN=''
        this.A_USER_ID=''
      },
      formVo: function() {
        this.ASP_NEWCUST_KEY_DTAIL=''
        this.CAM_TY=''
        this.CAM_TY_NM=''
        this.CAM_ID=''
        this.CAM_NM=''
        this.BUSI_TY=''
        this.BUSI_TY_NM=''
        this.REM=''
        this.FISH_YN=''
        this.FISH_YN_NM=''
        this.CAM_BEGIN_DATE=''
        this.CAM_BEGIN_TIME=''
        this.CAM_EOT_DATE=''
        this.CAM_EOT_TIME=''
        this.FAIL_CNT=''
        this.NOT_END_CNT=''
        this.CAN_NOT_CNT=''
        this.ING_CNT=''
        this.END_CNT=''
        this.EDPF_CNT=''
        this.EDFA_CNT=''
        this.EDTN_CNT=''
        this.EDSN_CNT=''
        this.ETC_CNT=''
        this.DIV_CNT=''
        this.NON_DIV_CNT=''
        this.TOT_CNT=''
      },
      searchData: {},
      formData: {},
      selectedData: [],
      dialogParamP01: {},
      dialogParamP02: {},
      dialogParamP03: {},
      dialogParamP05: {},
      isFormValid: true,
      formRulesfix: '잘못된 입력입니다.',
      formRules: {
        CAM_BEGIN_DT: {
          required: v => !!(v  && (v+'').trim()!=='') || formRulesfix
        },
        CAM_EOT_DT: {
          required: v => !!(v  && (v+'').trim()!=='') || formRulesfix
        },
      },
      textareaRules: [(v) => (v || '').length <= 200 || 'Max 200 characters']
    }
  },
  created() {
    this.searchData = new this.searchVo()
    this.formData = new this.formVo()
  },
  mounted() {
    this.initMain()
    this.initDetail()
  },
  methods: {
    async initMain() {
      this.gridObndCustList = []
      this.gridObndCustItems = []
      this.selectedData = []
      this.searchData = new this.searchVo()
      let pArr = ['RE010','OU002']
      this.commonCodeItems = await this.mixin_common_code_get_all(pArr)
    },
    initDetail() {
      this.gridObndDivList = []
      this.gridObndDivItems = []
      this.formData = new this.formVo()
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    showDialog(type) {
      this[`dialog${type}`] = true
    },
    hideDialog(type) {
      this[`dialog${type}`] = false
    },
    submitDialog(type) {
      this[`dialog${type}`] = false
    },
    addDialog(type) {
      this[`dialog${type}`] = true
    },
    transNumberFormat(v) {
      return String(v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    transStrToDate(p, delim) {
      let retVal = ''
      p = p.replace(/[^0-9]/g, '')
      if(!this.mixin_isEmpty(p) && typeof p === 'string' && p.length === 8) {
        if(this.mixin_isEmpty(delim)) delim = '/'
        p = p.substring(0,4) + '/' + p.substring(4,6) + '/' + p.substring(6,8)
        p = new Date(p)
        retVal = p.getFullYear() + delim + ((p.getMonth() < 9) ? '0' : '') + (p.getMonth() + 1) + delim + ((p.getDate() < 10) ? '0' : '') + p.getDate()
      }
      return retVal
    },
    transStrToTime(p, delim) {
      let retVal = ''
      p = p.replace(/[^0-9]/g, '')
      if(!this.mixin_isEmpty(p) && typeof p === 'string' && (p.length === 4 || p.length === 6)) {
        if(this.mixin_isEmpty(delim)) delim = ':'
        retVal = p.substring(0,2) + delim + p.substring(2,4) + delim + ((p.length === 6) ? p.substring(4,6) : '00')
      }
      return retVal
    },
    gridRowClick(item, row) {
      this.initDetail()
      this.gridRowActive(item)
      this.selectedData = []
      this.selectedData = new Array(item)
      if(!this.mixin_isEmpty(item.CAM_ID)) {
        for(const itemKey in item) {
          const val = item[itemKey]
          for(const formKey in this.formData) {
            if(formKey === itemKey) {
              if(itemKey.indexOf('_DATE')>-1) val = this.transStrToDate(val, '-')
              if(itemKey==='REM') val = this.restoreXSS(val)
              this.formData[itemKey] = val
            }
          }
        }
      }
      this.selectDetailInfo(item, row)
    },
    gridRowActive(item) {
      return (item === this.selectedData[0]) ? 'active' : ''
    },
    async selectRtn() {
      if(this.mixin_isEmpty(this.searchData.ASP_NEWCUST_KEY)) {
        this.common_alert(`회사구분란에서 회사를 선택해주세요.`, 'error')
        return false
      }
      if(this.mixin_isEmpty(this.searchData.START_OBND_DATE) || this.mixin_isEmpty(this.searchData.END_OBND_DATE)) {
        this.common_alert(`조회일자를 입력해주세요.`, 'error')
        return false
      }
      this.gridObndCustList = []
      this.gridObndCustItems = []
      this.selectedData = []
      this.initDetail()
      let reqData = {
        headers: {},
        sendData: {}
      }
      reqData.headers['SERVICE'] = 'phone.outbound.manage'
      reqData.headers['TYPE'] = 'XML_SERVICE'
      reqData.headers['URL'] = '/api/phone/outbound/manage/list'
      reqData.headers['ASYNC'] = false
      reqData.headers['SQL_NM'] = 'list'
      reqData.headers['GRID_ID'] = 'divGridObndList'
      reqData.headers['ROW_CNT'] = 50
      reqData.headers['PAGES_CNT'] = 1
      let params = JSON.parse(JSON.stringify(this.searchData))
      for(let d in params) {
        if(d.indexOf('_DATE')>-1) {
          params[d] = params[d].replace(/[^0-9]/g, '')
        }
      }
      params.A_USER_ID = this.geUserRole.userId
      reqData.sendData = params
      const res = await this.common_postCall(reqData)
      if(!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
        if(!this.mixin_isEmpty(res.DATA) && res.DATA.length > 0) {
          for(let d of res.DATA) {
            let val = ''
            for(let k of Object.keys(d)) {
              val = this.mixin_isEmpty(d[k]) ? '' : d[k]
              if(k.indexOf('_DATE')>-1) {
                val = this.transStrToDate(val)
              }else if(k.indexOf('_TIME')>-1) {
                val = this.transStrToTime(val)
              }
              d[k] = val
            }
            d['ASP_NEWCUST_KEY_DTAIL'] = this.searchData.ASP_NEWCUST_KEY
            this.gridObndCustItems.push(d)
          }
        }
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }
    },
    async selectDetailInfo(item, row) {
      let reqData = {
        headers: {},
        sendData: {}
      }
      reqData.headers['SERVICE'] = 'phone.outbound.manage'
      reqData.headers['METHOD'] = 'detail'
      reqData.headers['TYPE'] = 'BIZ_SERVICE'
      reqData.headers['URL'] = '/api/phone/outbound/manage/detail'
      reqData.headers['ASYNC'] = false
      reqData.sendData = { 'CAM_ID': item.CAM_ID }
      const res = await this.common_postCall(reqData, false)
      if(!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
        if(!this.mixin_isEmpty(res.DATA) && res.DATA.length > 0) {
          res.DATA.forEach(v => {
            Object.keys(v).forEach(k => {
              if(k!=='REM') this.formData[k] = v[k]
            })
          })
          this.formData.NON_DIV_CNT = (this.mixin_isEmpty(this.formData.TOT_CNT) ? 0 : Number(this.formData.TOT_CNT)) - (this.mixin_isEmpty(this.formData.DIV_CNT) ? 0 : Number(this.formData.DIV_CNT))
        }
      }
      if(item.FISH_YN_NM === '완료') {
        this.btnEndText = '마감취소'
      }else {
        this.btnEndText = '마감'
      }
      reqData.headers = {}
      reqData.sendData = {}
      reqData.headers['SERVICE'] = 'phone.outbound.manage'
      reqData.headers['SQL_NM'] = 'outbnd-list'
      reqData.headers['GRID_ID'] = 'divGridObndDivInfo'
      reqData.headers['ASYNC'] = false
      reqData.headers['TYPE'] = 'XML_SERVICE'
      reqData.headers['URL'] = '/api/phone/outbound/manage/outbnd-list'
      reqData.headers['ROW_CNT'] = 50
      reqData.headers['PAGES_CNT'] = 1
      reqData.sendData = { 'CAM_ID': item.CAM_ID, 'A_USER_ID': this.geUserRole.userId }
      const res2 = await this.common_postCall(reqData, false)
      if(!this.mixin_isEmpty(res2) && !res2.HEADER.ERROR_FLAG) {
        if(!this.mixin_isEmpty(res2.DATA) && res2.DATA.length > 0) {
          for(let d of res2.DATA) {
            let val = ''
            for(let k of Object.keys(d)) {
              val = this.mixin_isEmpty(d[k]) ? '' : d[k]
              if(k.indexOf('_CNT')>-1) {
                val = this.transNumberFormat(val)
              }
              d[k] = val
            }
            d['ROW_NUMBER'] = this.gridObndDivItems.length + 1
            this.gridObndDivItems.push(d)
          }
        }
      }
    },
    async deleteDetail(reqData) {
      reqData.headers['SERVICE'] = 'phone.outbound.manage'
      reqData.headers['METHOD'] = 'delete'
      reqData.headers['TYPE'] = 'BIZ_SERVICE'
      reqData.headers['URL'] = '/api/phone/outbound/manage/delete'
      reqData.headers['ASYNC'] = false
      let params = JSON.parse(JSON.stringify(this.formData))
      for(let d in params) {
        if(d.indexOf('_DATE')>-1 || d.indexOf('_TIME')>-1) {
          params[d] = params[d].replace(/[^0-9]/g, '')
        }
      }
      params['CAM_ID'] = params.CAM_ID
      params['CHNG_MAN'] = this.geUserRole.userId
      params['USE_YN'] = 'N'
      params['ASP_NEWCUST_KEY'] = params.ASP_NEWCUST_KEY_DTAIL
      reqData.sendData = params
      let res = await this.common_postCall(reqData)
      if(!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
        this.common_alert(`정상적으로 삭제되었습니다.`, 'done')
        this.selectRtn()
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }
    },
    processRtn(type) {
      if(this.mixin_isEmpty(this.selectedData) || this.selectedData.length < 1) {
        this.common_alert(`아웃바운드 선택을해주세요.`, 'error')
        return false
      }
      let reqData = {
        headers: {},
        sendData: {}
      }
      switch(type) {
        case 'DEL':
          this.common_confirm(`아웃바운드를(을) 삭제하시겠습니까?`, async ()=>{
            const divCnt = this.mixin_isEmpty(this.formData.DIV_CNT) ? 0 : Number(this.formData.DIV_CNT)
            const notEndCnt = this.mixin_isEmpty(this.formData.NOT_END_CNT) ? 0 : Number(this.formData.NOT_END_CNT)
            if(divCnt !== notEndCnt) {
              this.common_alert(`배분 건수와 미시도 건수가 맞지 않습니다.<br/>다시 확인해주세요.`, 'error')
              return false
            }
            if(divCnt > 0) {
              this.common_confirm(`배분건수가 있습니다. 삭제하시겠습니까?`, async ()=>{
                await this.deleteDetail(reqData)
              },'','','','error')
            }else {
              await this.deleteDetail(reqData)
            }
          },'','','','error')
          break

        case 'UPDT':
          if(!this.$refs.form.validate()) return false
          if(this.btnEndText === '마감취소') {
            this.common_alert(`마감처리 상태이므로<br/>수정이 불가능합니다.`, 'error')
            return false
          }
          this.common_confirm(`아웃바운드를(을) 수정하시겠습니까?`, async ()=>{
            reqData.headers['SERVICE'] = 'phone.outbound.manage'
            reqData.headers['METHOD'] = 'modify'
            reqData.headers['TYPE'] = 'BIZ_SERVICE'
            reqData.headers['URL'] = '/api/phone/outbound/manage/modify'
            reqData.headers['ASYNC'] = false
            let params = JSON.parse(JSON.stringify(this.formData))
            for(let d in params) {
              if(d.indexOf('_DATE')>-1 || d.indexOf('_TIME')>-1) {
                params[d] = params[d].replace(/[^0-9]/g, '')
              }else if(d === 'REM') {
                params[d] = this.restoreXSS_Dec(params[d])
              }
            }
            params['ASP_NEWCUST_KEY'] = params.ASP_NEWCUST_KEY_DTAIL
            params['REG_MAN'] = this.geUserRole.userId
            params['CHNG_MAN'] = this.geUserRole.userId
            reqData.sendData = params
            let res = await this.common_postCall(reqData)
            if(!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
              this.common_alert(`정상적으로 수정되었습니다.`, 'done')
              this.selectRtn()
            }else {
              this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
            }
          })
          break

        case 'END':
          this.common_confirm(`마감(마감취소) 처리하시겠습니까?`, async ()=>{
            reqData.headers['SERVICE'] = 'phone.outbound.manage.clos'
            reqData.headers['METHOD'] = 'process'
            reqData.headers['TYPE'] = 'BIZ_SERVICE'
            reqData.headers['URL'] = '/api/phone/outbound/manage/clos/process'
            reqData.headers['ASYNC'] = false
            let params = {}
            params['CAM_ID'] = this.formData.CAM_ID
            params['DATA_FLAG'] = 'U'
            params['FISH_YN'] = (this.btnEndText === '마감') ? 'Y' : 'N'
            params['REG_MAN'] = this.geUserRole.userId
            params['CHNG_MAN'] = this.geUserRole.userId
            reqData.sendData = params
            let res = await this.common_postCall(reqData)
            if(!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
              this.common_alert(`정상적으로 수정되었습니다.`, 'done')
              this.selectRtn()
            }else {
              this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
            }
          })
          break
      }
    },
    openPopup(popNm, evtNm) {
      switch(popNm) {
        case 'M110402P01':  //사용자근태
          if(this.mixin_isEmpty(this.searchData.ASP_NEWCUST_KEY)) {
            this.common_alert(`회사명을 선택해주세요.`, 'error')
            return false
          }
          this.dialogParamP01 = {}
          this.dialogParamP01['ASP_NEWCUST_KEY'] = this.searchData.ASP_NEWCUST_KEY
          break

        case 'M110402P02':  //사용자배분
          if((this.mixin_isEmpty(this.gridObndCustList) || this.gridObndCustList.length < 1) && 'dblClick' !== evtNm) {
            this.common_alert(`원하는 아웃바운드를 체크해주세요.`, 'error')
            return false
          }
          if('dblClick' === evtNm) this.dialogParamP02 = this.selectedData
          else this.dialogParamP02 = this.gridObndCustList
          break

        case 'M110402P03':  //고객단건등록
          if(this.mixin_isEmpty(this.selectedData) || this.selectedData.length < 1) {
            this.common_alert(`아웃바운드 선택을해주세요.`, 'error')
            return false
          }
          this.dialogParamP03 = this.selectedData.at(0)
          this.dialogParamP03["ASP_NEWCUST_KEY_DTAIL"] = this.formData.ASP_NEWCUST_KEY_DTAIL
          if(this.selectedData.at(0).FISH_YN === 'Y') {
            this.common_alert(`마감처리 상태이므로<br/> 등록이 불가능합니다.`, 'error')
            return false
          }
          break
        // case 'M110402P05':  //사용자근태22
        //   if(this.mixin_isEmpty(this.searchData.ASP_NEWCUST_KEY)) {
        //     this.common_alert(`회사명을 선택해주세요.`, 'error')
        //     return false
        //   }
        //   this.dialogParamP05 = {}
        //   this.dialogParamP05['ASP_NEWCUST_KEY'] = this.searchData.ASP_NEWCUST_KEY
        //   break
      }
      this.showDialog(popNm)
    },
    excelDownload(table_id, file_name) {
      if((table_id==='divGridObndList' && this.gridObndCustItems.length < 1) || (table_id==='divGridObndDivInfo' && this.gridObndDivItems.length < 1)) {
        this.common_alert(`[엑셀출력 실패] 조회된 정보가 없습니다.`, 'error')
        return false
      }
      this.table_id = table_id
      this.file_name = file_name
      this.mixin_common_exportExcel()
    },
  },
  filters: {
    inputNumberFormat(v){
      return String(v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  computed: {
    ...mapGetters({
      geUserRole: 'userStore/GE_USER_ROLE',
      geUserCompany: 'userStore/GE_USER_COMPANY'
    }),
    autoSearchDateFormat(){
      return this.searchData.START_OBND_DATE + ' ~ ' + this.searchData.END_OBND_DATE
    },
    autoBeginDateFormat(){
      let camBeginDate = this.mixin_isEmpty(this.formData.CAM_BEGIN_DATE) ? '' : this.formatDate(this.formData.CAM_BEGIN_DATE)
      let camBeginTime = this.mixin_isEmpty(this.formData.CAM_BEGIN_TIME) ? '' : (this.formData.CAM_BEGIN_TIME.length==5 ? this.formData.CAM_BEGIN_TIME+':00' : this.formData.CAM_BEGIN_TIME)
      return camBeginDate + ' ' + camBeginTime
    },
    autoEotDateFormat(){
      let camEotDate = this.mixin_isEmpty(this.formData.CAM_EOT_DATE) ? '' : this.formatDate(this.formData.CAM_EOT_DATE)
      let camEotTime = this.mixin_isEmpty(this.formData.CAM_EOT_TIME) ? '' : (this.formData.CAM_EOT_TIME.length==5 ? this.formData.CAM_EOT_TIME+':00' : this.formData.CAM_EOT_TIME)
      return camEotDate + ' ' + camEotTime
    },
  },
}
</script>
