<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
     <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <!-- 고객단건등록 -->
      <template>
        <h2 class="tit-h2">
            아웃바운드 정보
        </h2>
        <div class="table-form mt-2">
          <table>
            <caption class="hide">
              아웃바운드 항목
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="500px" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  아웃바운드명
                </th>
                <td colspan="3">
                  <div class="form-inp full">
                    <v-text-field
                      outlined
                      hide-details
                      disabled
                      placeholder=""
                      v-model="outBndInfoData.name"
                    >
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">구분</th>
                <td colspan="3">
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    disabled
                    filled
                    v-model="outBndInfoData.group"
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">업무구분</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    disabled
                    filled
                    v-model="outBndInfoData.groupSe"
                  >
                  </v-text-field>
                </td>
                <th scope="row">완료구분</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    disabled
                    filled
                    v-model="outBndInfoData.compYn"
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">시작일자</th>
                <td>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    color="#FFF"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted1"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        filled
                      ></v-text-field>
                    </template>
                    <div class="d-flex align-center">
                      <v-date-picker
                          v-model="date"
                          dark
                          color="#F7AFAE"
                          no-title
                          show-current="false"
                          @change="startDate"
                          :first-day-of-week="1"
                          locale="ko-KR"
                          :day-format="mixin_getDate"
                        ></v-date-picker>
                        <v-time-picker
                          v-model="timeStep"
                          color="#F7AFAE"
                          format="24hr"
                        ></v-time-picker>
                      </div>
                    <div class="text-center pt-3 pb-3">
                      <v-btn
                        outlined
                        small
                        class="btn-point ml-3"
                        @click="menu2 = false"
                      >확인</v-btn>
                    </div>
                  </v-menu>
                </td>
                <th scope="row">종료일자</th>
                <td>
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    color="#FFF"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted2"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        disabled
                        filled
                        class="form-inp icon-calendar full"
                        append-icon="svg-calendar"
                      ></v-text-field>
                    </template>
                    <div class="d-flex align-center">
                      <v-date-picker
                          v-model="date2"
                          dark
                          color="#F7AFAE"
                          no-title
                          show-current="false"
                          @change="endDate"
                          :first-day-of-week="1"
                          locale="ko-KR"
                          :day-format="mixin_getDate"
                        ></v-date-picker>
                        <v-time-picker
                          v-model="timeStep"
                          color="#F7AFAE"
                          format="24hr"
                        ></v-time-picker>
                      </div>
                    <div class="text-center pt-3 pb-3">
                      <v-btn
                        outlined
                        small
                        class="btn-point ml-3"
                        @click="menu3 = false"
                      >확인</v-btn>
                    </div>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h2 class="tit-h2 mt-3">
            아웃바운드 고객정보
        </h2>
        <v-form
            ref="form"
            lazy-validation>
          <div class="table-form mt-2 mb-2">
            <table>
              <caption class="hide">
                아웃바운드 항목
              </caption>
              <colgroup>
                <col width="127px" />
                <col width="500px" />
                <col width="127px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">주민등록번호</v-badge>
                  </th>
                  <td>
                   <v-text-field
                      class="form-inp full"
                      name="name"
                      value=""
                      id="id"
                      outlined
                      hide-details=""
                      maxlength="13"
                      v-model="outBndCusData.idNum"
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">고객명</v-badge>
                  </th>
                  <td>
                   <v-text-field
                      class="form-inp full"
                      name="name"
                      value=""
                      id="id"
                      outlined
                      hide-details=""
                      v-model="outBndCusData.name"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">휴대전화</th>
                  <td >
                    <v-text-field
                      class="form-inp full"
                      name="name"
                      value=""
                      id="id"
                      outlined
                      hide-details=""
                      @blur="methodBlur"
                      v-model="outBndCusData.phoneNum"
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">자택전화</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      name="name"
                      value=""
                      id="id"
                      outlined
                      hide-details=""
                      v-model="outBndCusData.homeNum"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">직장전화</th>
                  <td colspan="3">
                    <v-text-field
                      class="form-inp full"
                      name="name"
                      value=""
                      id="id"
                      outlined
                      hide-details=""
                      v-model="outBndCusData.jobNum"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">직업상세명</th>
                  <td colspan="3">
                   <v-text-field
                      class="form-inp full"
                      name="name"
                      value=""
                      id="id"
                      outlined
                      hide-details=""
                      v-model="outBndCusData.jobDetail"
                    >
                    </v-text-field>
                  </td>

                </tr>
                <tr>
                  <th scope="row">비고</th>
                  <td colspan="3">
                    <v-textarea
                      class="textarea-type0"
                      label=""
                      no-resize
                      outlined
                      height="100px"
                      v-model="outBndCusData.memo"
                    ></v-textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-form>
      </template>
      <div class="text-right mt-3">
        <template>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInit')" outlined class="btn-point" @click="inputTextReset">초기화</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnRegiNoDist')" outlined class="btn-point ml-2" @click="clickBtnReg('divNo')">미배분등록</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnRegi')" outlined class="btn-127px ml-2" @click="clickBtnReg('divYes')" >등록</v-btn>
        </template>
      </div>
    </div>
  </div>

</template>

<script>

import {mixin} from "@/mixin/mixin";

export default {
  //:allowed-minutes="allowedStep"
  name: "MENU_M110402P03", //name은 'MENU_' + 파일명 조합
  props: {
    headerTitle: {
      type: String,
      default: "제목",
    },
    p03Param: {
      type: Object,
    },
  },
  mixins:[mixin],

  data: () => ({
    outBndInfoData: {
      name    :'',
      group   :'',
      groupSe :'',
      compYn  :'',
      dateFr  :'',
      dateTo  :'',
      timeFr  :'',
      timeTo  :'',
    },
    outBndCusData: {
      idNum    :'',
      name     :'',
      phoneNum :'',
      homeNum  :'',
      jobNum   :'',
      jobDetail:'',
      memo     :'',
    },

    validateRules:{
      menuNm: [
        v => !!v || '메뉴명 은(는) 필수입력입니다.',
        // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
      ],
      nodeId: [
        v => !!v || '메뉴ID 은(는) 필수입력입니다.',
        // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
      ],
      viewTarget: [
        v => !!v || '뷰타겟 은(는) 필수입력입니다.',
        // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
      ],
      useYn: [
        v => !!v || '사용여부 은(는) 필수입력입니다.',
        // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
      ],
      sort: [
        v => !!v || '정렬번호는 필수입니다.',
        //v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
        v => (v >= 0 && v < 100 ) || '0보다 작은 숫자는 입력할 수 없습니다.',
      ],

      btnType: [
        v => !!v || '버튼타입 은(는) 필수입력입니다.',
        //v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
      ],
      btnId: [
        v => !!v || '버튼ID 은(는) 필수입력입니다.',
        //v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
      ],
      btnNm: [
        v => !!v || '버튼명 은(는) 필수입력입니다.',
        //v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
      ],

      cd: [
        v => !!v || '코드는 필수입니다.',
        // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
      ],
      cdNm: [
        v => !!v || '공통코드명은 필수입니다.',
      ],
      cdUseFrDt: [
        v => !!v || '사용시작일은 필수입니다.',
        v => /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || '날짜는 YYYY-MM-DD 형식으로 작성해 주세요.',
      ],
      cdUseToDt: [
        v => !!v || '사용종료일은 필수입니다.',
        v => /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || '날짜는 YYYY-MM-DD 형식으로 작성해 주세요.',
      ],
      cdType: [
        v => !!v || '코드구분은 필수입니다.',
      ],
      chkNum: [
        //v => !!v || '정렬번호는 필수입니다.',
        v => /^[0-9]*$/.test(v) || '주민등록번호는 숫자만 입력 가능합니다.',
        //v => (v >= 0 && v < 10000 ) || '0 ~ 9999 이내로 입력해 주세요.',
      ],

      mobNum: [
        v => /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/.test(v) || "형식이 맞지 않습니다.",
      ],

      callNum: [
        v => /^\d{2,3}-\d{3,4}-\d{4}$/.test(v) || "형식이 맞지 않습니다.",
      ]
    },

    //================================================================

    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,

    footerHideTitle: "닫기",

    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dates: [
      (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    ],

    timeStep: '10:10',
    timeStep2: '10:10',

  }),

  methods: {
    async getInitDate() {
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["URL"]    = "/api/date/common/current-time/inqry";
      requestData.headers["SERVICE"]= "date.common.current-time";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["ASYNC"]  = false;
      requestData.headers["TYPE"]   = "BIZ_SERVICE";

      requestData.sendData["FORMAT01"] = "YYYY-MM-DD";

      let response = await this.common_postCall(requestData);
    },

    //구분 코드 불러오기
    async getInitGroupList() {
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["SERVICE"]= "code.common.code-book";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["ASYNC"]  = false;
      requestData.headers["TYPE"]   = "BIZ_SERVICE";
      requestData.headers["URL"]    = "/api/code/common/code-book/inqry";

      requestData.sendData["GROUP_CD"] = "RE010";

      let response = await this.common_postCall(requestData);
      this.getInitGroupListThen(response);
    },

    //구분 코드 불러오기 callback
    getInitGroupListThen(response) {
      this.outBndInfoData.name   = this.p03Param.CAM_NM;

      for(let i of response.RE010) {
        if(i.CD === this.p03Param.CAM_TY) this.outBndInfoData.group = i.CD_NM;
      }

      this.getInitGroupSeList();
    },

    //업무구분 코드 불러오기
    async getInitGroupSeList() {
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["SERVICE"] = "code.common.code-book";
      requestData.headers["METHOD"]  = "inqry";
      requestData.headers["ASYNC"]   = "false";
      requestData.headers["TYPE"]    = "BIZ_SERVICE";
      requestData.headers["URL"]     = "/api/code/common/code-book/inqry";

      requestData.sendData["GROUP_CD"] = "OU002";

      let response = await this.common_postCall(requestData);
      this.getInitGroupSeListThen(response);
    },

    //업무구분 코드 불러오기 callback
    getInitGroupSeListThen(response) {
      for(let i of response.OU002) {
        if(i.CD === this.p03Param.BUSI_TY) this.outBndInfoData.groupSe = i.CD_NM;
      }
      this.outBndInfoData.compYn = this.p03Param.FISH_YN;
    },

    //배분, 미배분 등록
    async clickBtnReg(paramYn) {
      if(!this.reqDataVal()) { return; }

      let requestData = { headers: {}, sendData: {} };

      requestData.headers["URL"]    = "/api/phone/outbound/manage/single-cstmr-regist-popup/regist";
      requestData.headers["SERVICE"]= "phone.outbound.manage.single-cstmr-regist-popup";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["ASYNC"]  = "false";
      requestData.headers["TYPE"]   = "BIZ_SERVICE";

      requestData.sendData["CUST_NO"]     = this.outBndCusData.idNum; //주민등록번호
      requestData.sendData["CUST_NM"]     = this.outBndCusData.name; //고객명
      requestData.sendData["MOBIL_NO"]    = this.outBndCusData.phoneNum; //휴대전화
      requestData.sendData["HOUSE_TEL_NO"]= this.outBndCusData.homeNum; //자택전화
      requestData.sendData["CO_TEL_NO"]   = this.outBndCusData.jobNum; //직장전화
      requestData.sendData["JOB_DTL_NM"]  = this.outBndCusData.jobDetail; //직업상세명
      requestData.sendData["REM"]         = this.restoreXSS_Dec(this.outBndCusData.memo); //비고
      requestData.sendData["CAM_ID"]      = this.p03Param.CAM_ID;
      requestData.sendData["REG_MAN"]     = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      requestData.sendData["CHNG_MAN"]    = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      requestData.sendData["IFLW_TY"]     = "03";
      requestData.sendData["DIV_YN"]      = paramYn == "divYes" ? "Y" : "N";
      requestData.sendData["ASP_NEWCUST_KEY"]= this.p03Param.ASP_NEWCUST_KEY_DTAIL;

      let response = await this.common_postCall(requestData);
      this.clickBtnRegThen(response);
    },

    //배분, 미배분 등록 callback
    clickBtnRegThen(response) {
      if(!response.HEADER.ERROR_FLAG) {
        this.common_alert("정상적으로 등록되었습니다.", "done");
        this.inputTextReset();
      } else {
        this.common_alert(response.HEADER.ERROR_MSG, "error");
      }
    },

    //입력필수값 체크(주민번호, 고객명)
    reqDataVal() {
      let checkNum = /[0-9]/;
      if(!checkNum.test(this.outBndCusData.idNum)) {
        this.common_alert("주민등록번호(사업자번호)는 숫자만 입력 가능합니다.", "error");
        return false;
      }

      if(this.mixin_isEmpty(this.outBndCusData.name)){
        this.common_alert("고객명은 필수입력입니다.", "error");
        return false;
      }

      if(!this.validate()){
        return;
      }
      return true;
    },

    //텍스트필드 초기화
    inputTextReset() {
      for(let item in this.outBndCusData) { this.outBndCusData[item] = ''; }
      console.log("textData 초기화 실행~~~")
    },

    //팝업 닫기
    clickClose() {
      this.inputTextReset();
    },

    //날짜 포맷 변경
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },

    methodBlur() {
      var regExp = /^[0-9]+$/;
      if(!regExp.test(this.outBndCusData.phoneNum)) this.outBndCusData.phoneNum = '';
      this.outBndCusData.phoneNum =
          this.outBndCusData.phoneNum.toString().replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    },


    //주민번호, 고객명 외 유효성 체크
    validate () {
      return this.$refs.form.validate();
    },

    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
  },

  created() {
  },

  mounted() {
    this.outBndInfoData.dateFr = this.p03Param.CAM_BEGIN_DATE;
    this.outBndInfoData.timeFr = this.p03Param.CAM_BEGIN_TIME;
    this.outBndInfoData.dateTo = this.p03Param.CAM_EOT_DATE;
    this.outBndInfoData.timeTo = this.p03Param.CAM_EOT_TIME;

    this.getInitGroupList();
  },

  updated() {
  },

  watch: {

  },

  computed: {
    computedDateFormatted1(){
      return `${this.outBndInfoData.dateFr} ${this.outBndInfoData.timeFr}`;
    },
    computedDateFormatted2(){
      return `${this.outBndInfoData.dateTo} ${this.outBndInfoData.timeTo}`;
    }
  },

  filters: {
    callNumVal: function (value) {
      console.log("callNumVal");
      return value.toString().replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      //return value.toString().replace(/\B(?=(\d{6})+(?!\d))/g, "-");
    }
  },

};
</script>

<style></style>
