<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
     <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <!-- 사용자배분 -->
      <template>
        <div class="">
          <h2 class="tit-h2 ">
            아웃바운드 리스트
          </h2>
          <div class="mt-2">
            <v-data-table
              dense
              :headers="gridDataHeaders"
              :items="gridDataText"
              item-key="CAM_ID"
              hide-default-footer
              class="grid-default case03"
              :items-per-page="30"
              :page.sync="page"
              @page-count="pageCount = $event"
              single-select
              @click:row="rowClick"
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
                <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="totalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
                ></v-pagination>
            </div>
            <p class="caption text-right">
              총 <strong class="clr-error">{{ gridDataText.length }}</strong
              >건
            </p>
          </div>
          <h2 class="tit-h2 mt-3">
            아웃바운드 상세
          </h2>
          <div class="table-form mt-2">
            <table>
              <caption class="hide">
                아웃바운드 항목
              </caption>
              <colgroup>
                <col width="127px" />
                <col width="200px" />
                <col width="127px" />
                <col width="200px" />
                <col width="127px" />
                <col width="200px" />
                <col width="127px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    아웃바운드
                  </th>
                  <td colspan="3">
                    <v-text-field
                      class="form-inp full"
                      outlined
                      hide-details
                      readonly
                      v-model="detailForm.CAM_NM"
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">캠페인구분</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      v-model="detailForm.CAM_TY_NM"
                      outlined
                      hide-details
                      readonly
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">업무구분</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      outlined
                      hide-details
                      readonly
                      v-model="detailForm.BUSI_TY_NM"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">총건수</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      outlined
                      hide-details
                      readonly
                      v-model="detailForm.TOT_CNT"
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">배분건수</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      outlined
                      hide-details
                      readonly
                      v-model="detailForm.DIV_CNT"
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">미배분건수</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      outlined
                      hide-details
                      readonly
                      v-model="detailForm.NON_DIV_CNT"
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">완료건수</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      outlined
                      hide-details
                      readonly
                      v-model="detailForm.END_CNT"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">최종시도실패건수</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      outlined
                      hide-details
                      readonly
                      v-model="detailForm.FAIL_CNT"
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">미시도건수</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      outlined
                      hide-details
                      readonly
                      v-model="detailForm.NOT_END_CNT"
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">처리중건수</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      outlined
                      hide-details
                      readonly
                      v-model="detailForm.ING_CNT"
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">처리불가건수</th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      outlined
                      hide-details
                      readonly
                      v-model="detailForm.CAN_NOT_CNT"
                    >
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 class="tit-h2 mt-3">
            사용자배분 리스트
          </h2>
          <div class="mt-2">
            <v-data-table
              dense
              :headers="gridDataHeaders2"
              :items="gridDataText2"
              height="240px"
              fixed-header
              item-key="CSLT_ID"
              v-model="gridSelectList2"
              hide-default-footer
              show-select
              class="grid-default"
              :items-per-page="30"
              :page.sync="page2"
              @page-count="pageCount2 = $event"

              :item-class="isActiveRow"
              @click:row="rowSelect"
            >
            <template v-slot:item.DIV_EDIT_COL="{ item }">
                <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    hide-details
                    v-model="item.DIV_EDIT_COL"
                    type="number"
                    min="0"
                    max="99"
                    outlined
                    @blur="checkNum(item)"
                    @change="changeDivEditCol(item)"
                >
                </v-text-field>
                </td>
            </template>
            </v-data-table>
            <div class="grid-paging text-center pt-2">
                <v-pagination
                v-model="page2"
                :length="pageCount2"
                :total-visible="totalVisible2"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
                ></v-pagination>
            </div>
          </div>
        </div>
      </template>
      <div class="text-right">
        <template>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInit')" outlined class="btn-point" @click="initGrid">초기화</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAllBack')" outlined class="btn-point ml-2" @click="setAllwtd">전체회수</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnBack')" outlined class="btn-default ml-2" @click="setWtd" >회수</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnIngWtd')" outlined class="btn-point ml-2" @click="setIngWtd" >인수인계</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSameDist')" outlined class="btn-point ml-2" @click="setDvs" >균등분배</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDist')" outlined class="btn-default ml-2" @click="setDsb" >배분</v-btn>
        </template>
      </div>
    </div>
    <!-- 아웃바운드 배분변경 대상 사용자 검색 모달 -->
    <v-dialog max-width="1400" persistent v-if="dialogM110402P04" v-model="dialogM110402P04" content-class="square-modal">
      <dialog-M110402P04
          ref="dialogM110402P04ref"
          headerTitle="아웃바운드 배분변경 대상 사용자 검색"
          :param=dialogParamP04
          @hide="hideDialog('M110402P04')"
          @submit="submitDialog('M110402P04')"
          @hideAndGetList="hideAndGetList('M110402P04')"
      >
      </dialog-M110402P04>
    </v-dialog>
  </div>

</template>

<script>
import { mixin } from "@/mixin/mixin.js"
import {mapGetters} from "vuex";
import DialogM110402P04 from "@/views/page/old/M110402P04.vue"
export default {
  name: "MENU_M110402P02", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components:{
    DialogM110402P04,
  },
  props: {
    param: {
      type: Array,
    },
    headerTitle: {
      type: String,
      default: "제목",
    },
  },
  data: () => ({
    dialogM110402P04: false, //아웃바운드 배분변경 대상 사용자 검색
    dialogParamP04:{},
    gridDataHeaders: [
      { text: "No", value: "ROW_NUMBER", align: "center", sortable: true, width: "70px" },
      { text: "아웃바운드명", value: "CAM_NM", align: "left", sortable:  true,},
      { text: "시작일자", value: "CAM_BEGIN_DATE", align: "center", sortable: false },
      { text: "종료일자", value: "CAM_EOT_DATE", align: "center", sortable: true, },
      { text: "미시도건수", value: "NOT_END_CNT", align: "center", sortable: true, },
      { text: "처리중건수", value: "ING_CNT", align: "center", sortable: true, },
      { text: "캠페인구분", value: "CAM_TY_NM", align: "center", sortable: true, },
    ],
    gridDataText: [],
    selectedRow: null,
    selectedRows: [],
    page: 1,
    page2: 1,
    pageCount: 0,
    pageCount2: 0,
    itemsPerPage: 5,
    itemsPerPage2: 100,
    totalVisible: 10,
    totalVisible2: 10,
    gridDataHeaders2: [
      { text: "No", value: "ROW_NUMBER", align: "center", sortable: true , width: "70px"},
      { text: "아이디", value: "CSLT_ID", align: "center", sortable:  true, },
      { text: "사용자명", value: "USER_NM", align: "center", sortable: true },
      { text: "배분", value: "DIV_CNT", align: "center", sortable: true, },
      { text: "완료", value: "END_CNT", align: "center", sortable: true, },
      { text: "미시도", value: "NOT_END_CNT", align: "center", sortable: true, },
      { text: "처리불가", value: "CAN_NOT_CNT", align: "center", sortable: true, },
      { text: "처리중", value: "ING_CNT", align: "center", sortable: true, },
      { text: "최종시도실패", value: "FAIL_CNT", align: "center", sortable: true, },
      { text: "추가/회수", value: "DIV_EDIT_COL", align: "center", sortable: false, },
    ],
    gridDataText2: [],
    gridSelectList2: [],
    gridDataText2_ori: [],
    detailForm: {
      CAM_ID: '',
      CAM_NM: '',
      CAM_TY: '',
      CAM_TY_NM: '',
      BUSI_TY_NM: '',
      TOT_CNT: 0,
      DIV_CNT: 0,
      NON_DIV_CNT: 0,
      END_CNT: 0,
      FAIL_CNT: 0,
      NOT_END_CNT: 0,
      ING_CNT: 0,
      CAN_NOT_CNT: 0,
    }
  }),
  async mounted() {
    await this.getList();
  },
  methods: {
    async getList(){
      await this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", true);
      await this.divGridCsltDivObndList(false);
      await this.selectCsltDivObndDetail(false);
      await this.divGridCsltDivInfo(false);
      await this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
    },
    // 아웃바운드 리스트
    async divGridCsltDivObndList(isLoadingbar){
      this.gridDataText = [];
      let camIds = this.getCamIds;

      let reqData = {
        headers: {},
        sendData: {}
      };
      reqData.headers['SERVICE']= 'phone.outbound.manage.agent-dstb-process-popup';
      reqData.headers['SQL_NM']= 'inqire';
      reqData.headers['GRID_ID']= 'divGridCsltDivObndList';
      reqData.headers['ASYNC']= 'false';
      reqData.headers['TYPE']= 'XML_SERVICE';
      reqData.headers['URL']= '/api/phone/outbound/manage/agent-dstb-process-popup/inqire';
      reqData.headers['ROW_CNT']= 50;
      reqData.headers['PAGES_CNT']= 1;

      reqData.sendData['CAM_ID_ARR'] = camIds;

      const res = await this.common_postCall(reqData, isLoadingbar);
      let dataList = res.DATA;
      for( let i in dataList ){
        this.gridDataText.push(dataList[i]);
      }

      if(dataList.length > 1){
        this.detailForm.CAM_NM = "다건 배분";
        this.detailForm.CAM_TY_NM = "다건 배분";
        this.detailForm.BUSI_TY_NM = "다건 배분";
      }else{
        this.detailForm.CAM_NM = dataList[0].CAM_NM;
        this.detailForm.CAM_TY_NM = dataList[0].CAM_TY_NM;
        this.detailForm.BUSI_TY_NM = dataList[0].BUSI_TY_NM;
      }
    },
    // 아웃바운드 상세
    async selectCsltDivObndDetail(isLoadingbar){
      let camIds = this.getCamIds;

      let reqData = {
        headers: {},
        sendData: {}
      };
      reqData.headers['SERVICE']= 'phone.outbound.manage.agent-dstb-process-popup';
      reqData.headers['METHOD']= 'detail';
      reqData.headers['ASYNC']= 'false';
      reqData.headers['TYPE']= 'BIZ_SERVICE';
      reqData.headers['URL']= '/api/phone/outbound/manage/agent-dstb-process-popup/detail';

      reqData.sendData['CAM_ID_ARR'] = camIds;

      const res = await this.common_postCall(reqData, isLoadingbar);


      let dataList = res.DATA;

      let t_totCnt = 0;
      let t_divCnt = 0;
      let t_endCnt = 0;
      let t_notEndCnt = 0;
      let t_canNotCnt = 0;
      let t_ingCnt = 0;
      let t_failCnt = 0;
      let t_etcCnt = 0;
      let t_edpfCnt = 0;
      let t_edfaCnt = 0;
      let t_edtnCnt = 0;
      let t_edsnCnt = 0;

      for( let i in dataList){
        t_totCnt += Number(this.inputZero(dataList[i].TOT_CNT));
        t_endCnt += Number(this.inputZero(dataList[i].END_CNT));
        t_notEndCnt += Number(this.inputZero(dataList[i].NOT_END_CNT));
        t_canNotCnt += Number(this.inputZero(dataList[i].CAN_NOT_CNT));
        t_ingCnt += Number(this.inputZero(dataList[i].ING_CNT));
        t_failCnt += Number(this.inputZero(dataList[i].FAIL_CNT));
        t_etcCnt += Number(this.inputZero(dataList[i].ETC_CNT));
        t_edpfCnt += Number(this.inputZero(dataList[i].EDPF_CNT));
        t_edfaCnt += Number(this.inputZero(dataList[i].EDFA_CNT));
        t_edtnCnt += Number(this.inputZero(dataList[i].EDTN_CNT));
        t_edsnCnt += Number(this.inputZero(dataList[i].EDSN_CNT));
      }
      if( dataList.length > 0 ){
        t_divCnt = Number(this.inputZero(dataList[0].DIV_CNT)); // 배분건수
      }else{
        t_divCnt = 0; // 배분건수
      }
      // 합쳐서 계산
      this.detailForm.TOT_CNT = t_totCnt; // 총건수
      this.detailForm.DIV_CNT = t_divCnt; // 배분건수
      this.detailForm.END_CNT = t_endCnt; // 완료건수
      this.detailForm.NOT_END_CNT = t_notEndCnt; // 미처리건수
      this.detailForm.CAN_NOT_CNT = t_canNotCnt; // 처리불가건수
      this.detailForm.ING_CNT = t_ingCnt; // 처리중건수
      this.detailForm.FAIL_CNT = t_failCnt; // 최종시도실패건수
      this.detailForm.ETC_CNT = t_etcCnt; // 기타건수
      this.detailForm.EDPF_CNT = t_edpfCnt; // 처리완료 완판
      this.detailForm.EDFA_CNT = t_edfaCnt; // 처리완료 불판
      this.detailForm.EDTN_CNT = t_edtnCnt; // 처리완료 10회시도
      this.detailForm.EDSN_CNT = t_edsnCnt; // 처리완료 안내장발송
      this.detailForm.NON_DIV_CNT = t_totCnt - t_divCnt; // 미배분건수

    },
    // 사용자배분 리스트
    async divGridCsltDivInfo(isLoadingbar){
      this.gridDataText2 = [];
      this.gridSelectList2 = [];
      let camIds = this.getCamIds;

      let reqData = {
        headers: {},
        sendData: {}
      };
      reqData.headers['SERVICE']= 'phone.outbound.manage.agent-dstb-popup.agent-dstb'
      reqData.headers['SQL_NM']= 'inqire'
      reqData.headers['GRID_ID']= 'divGridCsltDivInfo'
      reqData.headers['ASYNC']= 'false'
      reqData.headers['TYPE']= 'XML_SERVICE'
      reqData.headers['URL']= '/api/phone/outbound/manage/agent-dstb-popup/agent-dstb/inqire'
      reqData.headers['ROW_CNT']= 50
      reqData.headers['PAGES_CNT']= 1

      reqData.sendData['ASP_NEWCUST_KEY'] = this.objList[0].ASP_NEWCUST_KEY_DTAIL;
      reqData.sendData['CAM_ID_ARR'] = camIds;
      reqData.sendData['TENANT_ID'] = "ARGO1";

      const res = await this.common_postCall(reqData, isLoadingbar);
      let dataList = res.DATA;
      for( let i in dataList ){
        dataList[i]["ROW_NUMBER"] = Number(i) + 1;
        dataList[i]["DIV_EDIT_COL"] = 0;
        this.gridDataText2.push(dataList[i]);
      }
      this.gridDataText2_ori = JSON.parse(JSON.stringify(this.gridDataText2));
    },
    // 초기화
    initGrid(){
      this.gridDataText2 = [];
      this.gridSelectList2 = [];
      this.gridDataText2 = JSON.parse(JSON.stringify(this.gridDataText2_ori));
    },
    // 균등분배
    setDvs(){
      let chkCnt = this.gridSelectList2.length;
      // this.gridSelectList2
      // 2. 균등분배할 대상 체크박스 체크 여부 확인 : 사용자를 선택해주세요.
      if( chkCnt < 1 ){
        this.common_alert("사용자를 선택해주세요.", "error");
        return;
      }

      // 1. DIV_EDIT_COL 0으로 초기화
      for( let i in this.gridDataText2 ){
        this.gridDataText2[i].DIV_EDIT_COL = 0;
      }
      let divCnt = this.detailForm.NON_DIV_CNT;
      // 3. 균등분배 값 = 미배분 건수 / 선택한 목록 갯수   divNum = Math.floor(divCnt / chkCnt);
      let divNum = Math.floor( this.detailForm.NON_DIV_CNT / chkCnt );

      // 4. 추가할 건수 (반차는 반 /나머지는 풀)
      let divNumChg = 0;
      let divNumTotal = 0;
      if(divNum > 0) {
        for( let i in this.gridSelectList2 ){
          // 반차 인 경우
          if(this.gridSelectList2[i].HALFD_YN == 'Y' ){
            // 나눌수의 반만 준다.
            divNumChg = Number(this.gridSelectList2[i].DIV_EDIT_COL) + Math.floor(divNum / 2);
          }else{
            // 그대로 준다.
            divNumChg = Number(this.gridSelectList2[i].DIV_EDIT_COL) + divNum;
          }
          // 배분된 총 수
          divNumTotal += divNumChg;
          // 해당 담당자에게 배분할 값을 세팅
          this.gridSelectList2[i].DIV_EDIT_COL = Number(this.gridSelectList2[i].DIV_EDIT_COL) + divNumChg;
          //$("#divGridCsltDivInfo").setCellData(gridChkDataObj[idx].divGridCsltDivInfo_ROWNUM-1, "DIV_EDIT_COL", Number(gridChkDataObj[idx].DIV_EDIT_COL) + divNumChg );
        }
        /*
				for(var idx=0; idx < chkCnt; idx++) {
					// 반차 인 경우
					if( gridChkDataObj[idx].HALFD_YN == 'Y' ){
					  // 나눌수의 반만 준다.
						divNumChg = Number(gridChkDataObj[idx].DIV_EDIT_COL) + Math.floor(divNum / 2);
					}else{
					  // 그대로 준다.
						divNumChg = Number(gridChkDataObj[idx].DIV_EDIT_COL) + divNum
					}
					// 배분된 총 수
					divNumTotal += divNumChg;
					// 해당 담당자에게 배분할 값을 세팅
					$("#divGridCsltDivInfo").setCellData(gridChkDataObj[idx].divGridCsltDivInfo_ROWNUM-1, "DIV_EDIT_COL", Number(gridChkDataObj[idx].DIV_EDIT_COL) + divNumChg );
				}
         */
			}
      let rmdNum2 = divCnt - divNumTotal;
      // 5-1. 방법 1 : 배분하고 남은 값을 다시 사용자수로 나눠서 그 몫을 균등하게 배분 ( 남은건수/체크한사용자수 = 몫 ) =============*/ var rmdNum2 = divCnt - divNumTotal;
      let divRlt = parseInt( rmdNum2 / chkCnt );
      if( divRlt > 0 ){
        do{
          if(divRlt > 0) {
            // loop 배분
            for( let i in this.gridSelectList2 ){
              // 반차가 아닌 경우만
              if( this.gridSelectList2[i].HALFD_YN == 'Y' ) {
                rmdNum2 -= parseInt(divRlt/2);
                this.gridSelectList2[i].DIV_EDIT_COL = Number(this.gridSelectList2[i].DIV_EDIT_COL) + parseInt(divRlt/2) ;
              }else {
                rmdNum2 -= divRlt;
                this.gridSelectList2[i].DIV_EDIT_COL = Number(this.gridSelectList2[i].DIV_EDIT_COL) + parseInt(divRlt) ;
              }
            }
          } else break;
          divRlt = parseInt( rmdNum2 / chkCnt ); // 남은건수/체크한사용자수 = 몫
        }while( divRlt > 0 )
      }
      // 나머지 값 배분
      if( rmdNum2 > 0){
        do{
          // loop 배분
          for( let i in this.gridSelectList2 ){
            if(rmdNum2 > 0) {
              // 반차가 아닌 경우만
              //if( gridChkDataObj[idx].HALFD_YN != 'Y' ){
              rmdNum2--;
              this.gridSelectList2[i].DIV_EDIT_COL = Number(this.gridSelectList2[i].DIV_EDIT_COL) + 1 ;
              //}
            } else break;
          }
        }while( rmdNum2 > 0 )
      }
      // 5-2  방법 2 : 처음만 사용자수로 나눈 몫을 배분하고( 반차는 몫/2만 ) 나머지는 짝수일 경우에만 반차가 들어가게 처리
    },
    // 배분
    setDsb(){
      let chkCnt = this.gridSelectList2.length;
      // this.gridSelectList2
      // 2. 균등분배할 대상 체크박스 체크 여부 확인 : 사용자를 선택해주세요.
      if( chkCnt < 1 ){
        this.common_alert("사용자를 선택해주세요.", "error");
        return;
      }
      let totChgCnt = 0; // 추가/회수 총건수
      for( let i in this.gridSelectList2 ){
        totChgCnt += Number(this.gridSelectList2[i].DIV_EDIT_COL);
      }
      if(totChgCnt == 0) {
        this.common_alert("배분 될 건수가 없습니다.", "error");
        return;
      }
      let exceed = totChgCnt - this.detailForm.NON_DIV_CNT; // 추가/회수건수 - 미배분건수
      if(exceed > 0) {
        this.common_alert("배분 될 건수의 총합이 미배분 건수보다 큽니다(+"+ exceed + ").", "error");
        return;
      }
      this.common_confirm("배분처리하시겠습니까", this.setDsbYesCall, totChgCnt, null, null);
    },
    async setDsbYesCall(totChgCnt){
      let data = "";
      let gridChkDataObj = this.gridSelectList2;
      // 사용자 및 배분 정보
      for(let i=0; i < gridChkDataObj.length; i++) {
        let num = Number( gridChkDataObj[i].DIV_EDIT_COL );
        if(num > 0) {
          data += gridChkDataObj[i].CSLT_ID + ":" + num + "/";
        }
      }
      // 아웃바운드 리스트 (grid Data)
      let camIdCntData = ""; // 아웃바운드에 따른 미배분 건수 배열
      let gridAllObndDataObj = this.gridDataText;
      if( gridAllObndDataObj.length === 0 ){
        this.common_alert("아웃바운드 정보가 없습니다.", "W");
        this.$emit('hide');
        return;
      }

      for(let z = 0; z<gridAllObndDataObj.length; z++ ){
        //미배분건수 = 총건수 - 배분건수
        let nondivCnt = Number(this.inputZero(gridAllObndDataObj[z].TOT_CNT)) - Number(this.inputZero(gridAllObndDataObj[z].DIV_CNT));
        camIdCntData += gridAllObndDataObj[z].CAM_ID + ":" + nondivCnt + "/";
      }
      //서버에 전송할 파라메터객체 생성
      let reqData = {
        headers: {},
        sendData: {}
      };
      reqData.headers['SERVICE'] = 'phone.outbound.manage.agent-dstb-process-popup';
      reqData.headers['METHOD'] = 'modify';
      reqData.headers['TYPE'] = 'BIZ_SERVICE';
      reqData.headers['URL'] = '/api/phone/outbound/manage/agent-dstb-process-popup/modify';
      reqData.headers['ASYNC'] = false;

      // 파라미터 세팅
      reqData.sendData['DATA_FLAG'] = "U";
      reqData.sendData['DSTR_INFO'] = data.substring(0, data.length -1);
      reqData.sendData['CAM_ID_INFO'] = camIdCntData.substring(0, camIdCntData.length -1);
      reqData.sendData['CHNG_MAN'] = this.geUserRole.userId;
      reqData.sendData['REG_MAN'] = this.geUserRole.userId;
      reqData.sendData['TOT_CHG_CNT'] = totChgCnt;
      reqData.sendData['ASP_NEWCUST_KEY'] = this.aspNewcustKey;

      const res = await this.common_postCall(reqData);
      if(!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
        this.common_alert("정상적으로 배분되었습니다.");
        await this.getList();
      }
    },
    setWtd(){
      if( this.gridDataText.length > 1){
        this.common_alert("다건 인 경우 회수를 할 수 없습니다.", "error");
        return;
      }
      // 회수 ( 상태가 미시도인 경우만 AS-IS에 상태가 처리중인 건은 주석처리 되어있음 )
      let exceed = 0;
      let totCnt = this.gridDataText2.length;
      // grid Data 전체
      let gridAllDataObj = this.gridDataText2;
      // 선택한 obj
      let gridChkDataObj = this.gridSelectList2;
      if( gridChkDataObj.length == 0 ){this.common_alert("사용자을(를) 선택해주세요.", "error");return;}

      let totChgCnt = 0; // 추가/회수 총건수
      for(let i = 0; i<gridChkDataObj.length; i++){
        totChgCnt+= Number(gridChkDataObj[i].DIV_EDIT_COL);
      }
      if(totChgCnt == 0) {
        this.common_alert("회수 될 건수를 입력해주십시요.", "error");
        return;
      }

      // 미시도 회수 ( 처리중 상태값 변경은 AS-IS 소스상에서 없음)
      exceed = totChgCnt - Number(this.detailForm.NOT_END_CNT); // 총건수 - 미시도건수
      if(exceed > 0) {
        this.common_alert("회수될건수의 총합이 회수가능건수(미시도, 처리중)보다 큽니다.("+exceed+")", "error");
        return;
      }

      for(let i=0; i < gridChkDataObj.length; i++) {
        let num =  Number( this.inputZero(gridChkDataObj[i].DIV_EDIT_COL) ); // EDIT 값
        let notEndCnt = Number( this.inputZero(gridChkDataObj[i].NOT_END_CNT) ); // 미시도건수)(gird)
        let ingCnt = Number( this.inputZero(gridChkDataObj[i].ING_CNT) ); // 처리중건수(gird)

        exceed = num - notEndCnt;

        if(exceed > 0) {
          let empNm = gridChkDataObj[i].USER_NM
          this.common_alert( gridChkDataObj[i].ROW_NUMBER + "번의 " + empNm + " 사용자의 회수될건수가 회수가능건수(미시도, 처리중)보다 큽니다.(+" +exceed + ")", "error" );
          return;
        }
      }
      let params = {
        'gridChkDataObj': gridChkDataObj,
        'totChgCnt': totChgCnt
      };
      this.common_confirm("회수처리하시겠습니까?", this.setWtdYesCall, params,null, null);
    },
    async setWtdYesCall(params){
      let gridChkDataObj = params["gridChkDataObj"];
      let totChgCnt = params["totChgCnt"];
      //nDivCnt = 0;

      // 아웃바운드 미시도 회수 정보
      let data = "";
      for(let i=0; i < gridChkDataObj.length; i++) {
        let num = Number(this.inputZero(gridChkDataObj[i].DIV_EDIT_COL)); //EDIT 값
        //let notEndCnt = Number(this.inputZero(gridChkDataObj[i].NOT_END_CNT));

        /* as-is 에서는 nDivCnt, notEndCntForWtd, ingCntForWtd 를 전역변수로 선언해서 썼으나 불필요해보여서 일단 주석처리함.
        if(num > notEndCnt){
          notEndCntForWtd += notEndCnt;
          ingCntForWtd += (num - notEndCnt);
        }else{
          notEndCntForWtd += num;
        }
        */
        if(num > 0) {
          data +=  gridChkDataObj[i].CSLT_ID  +  ":" + num + "/";
        }
      }

      // 아웃바운드 리스트 (grid Data)
      let camIdCntData = ""; // 아웃바운드에 따른 미배분 건수 배열
      let gridAllObndDataObj = this.gridDataText;
      if( gridAllObndDataObj.length === 0 ){
        this.common_alert("아웃바운드 정보가 없습니다.", "error");
        this.$emit('hide');
        return;
      }

      for(let z = 0; z<gridAllObndDataObj.length; z++ ){
        let nondivCnt = Number(this.inputZero(gridAllObndDataObj[z].TOT_CNT)) - Number(this.inputZero(gridAllObndDataObj[z].DIV_CNT))
        camIdCntData += gridAllObndDataObj[z].CAM_ID + ":" + nondivCnt + "/";
      }
      //서버에 전송할 파라메터객체 생성
      let reqData = {
        headers: {},
        sendData: {}
      };
      reqData.headers['SERVICE'] = 'phone.outbound.manage.agent-dstb-popup.modify-at'; 	//호출 CLASS 서비스ID 정의
      reqData.headers['METHOD'] = 'inqire'; // 아웃바운드고객배분여부수정 및 아웃바운드 배분 정보 삭제
      reqData.headers['TYPE'] = 'BIZ_SERVICE';
      reqData.headers['URL'] = '/api/phone/outbound/manage/agent-dstb-popup/modify-at/inqire';
      reqData.headers['ASYNC'] = false;

      // 파라미터 세팅
      reqData.sendData['DATA_FLAG'] = "U"; // 등록 및 수정할 사용자정보 파라메터 세팅
      reqData.sendData['CAM_ID_INFO'] = camIdCntData.substring(0, camIdCntData.length -1);
      reqData.sendData['FISH_TY'] = "00002"; // 미시도
      // reqData.sendData['FISH_TY'] = "00004"; // 처리중
      reqData.sendData['DSTR_INFO'] = data.substring(0, data.length -1);
      reqData.sendData['TOT_CHG_CNT'] = totChgCnt;
      reqData.sendData['REG_MAN'] = this.geUserRole.userId;
      reqData.sendData['CHNG_MAN'] = this.geUserRole.userId;
      reqData.sendData['ASP_NEWCUST_KEY'] = this.aspNewcustKey;

      //비즈니스 서비스 호출하여 결과값을 받는다.
      const res = await this.common_postCall(reqData);
      if(!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
        this.common_alert("정상적으로 수정되었습니다.");
        await this.getList();
      }
    },
    // 전체회수
    setAllwtd(){
      // 그리드 전체 obj
      const allRowData = this.gridDataText2;
      if( allRowData === null ){this.common_alert("사용자를 선택해주세요.", "error");return;}

      // 선택한 obj
      let gridChkDataObj = this.gridSelectList2;

      if(gridChkDataObj.length > 0) {
        // 선택값이 있는 경우
        for(let i=0; i < gridChkDataObj.length; i++) {
          // as-is는 미시도건수 + 처리중건수 였으나 실제 회수 처리 시 처리중 건은 회수가 불가능하여 제외
          gridChkDataObj[i].DIV_EDIT_COL = Number(gridChkDataObj[i].NOT_END_CNT) //+ Number(gridChkDataObj[i].ING_CNT);
        }
      }else{
        // 선택값이 없는 경우
        for(let i=0; i < allRowData.length; i++) {
          // as-is는 미시도건수 + 처리중건수 였으나 실제 회수 처리 시 처리중 건은 회수가 불가능하여 제외
          let value = Number(allRowData[i].NOT_END_CNT)// + Number(allRowData[i].ING_CNT);
          allRowData[i].DIV_EDIT_COL = value;
          if(value > 0){
            this.changeDivEditCol(allRowData[i]);
          }
        }
      }
    },
    // 인수인계 (처리중) - 처리중 인 건수 가 있을 때 다른 사용자에게 인수인계
    setIngWtd(){
      let gridAllDataObj = this.gridDataText2;
      // 선택한 obj
      let gridChkDataObj = this.gridSelectList2;
      //gridChkDataObj[0].aspkey = ASP_NEWCUST_KEY;
      if( gridChkDataObj.length === 0 ){this.common_alert("사용자을(를) 선택해주세요.", "error");return;}

      let totChgCnt = 0; // 추가/회수 총건수
      for(let i = 0; i<gridChkDataObj.length; i++){
        totChgCnt+= this.inputZero(Number(gridChkDataObj[i].DIV_EDIT_COL));
      }
      if(totChgCnt == 0) {
        this.common_alert("인수인계 건수를 입력해주십시요.", "error");
        return;
      }

      let flag = true;
      for( let i = 0; i<gridChkDataObj.length; i++){
        // 진행중(처리중) - 수정건수
        if( Number(this.inputZero(gridChkDataObj[i].ING_CNT)) < Number(this.inputZero(gridChkDataObj[i].DIV_EDIT_COL)) ){
          flag = false;
          break;
        }
      }
      if( !flag ){ this.common_alert("인수인계 건수가 처리중 건수 보다 큽니다.", "error");return; }

      // 아웃바운드 CAM_ID
      //this.getCamIds;
      // 아웃바운드 사용자변경 팝업
      this.dialogParamP04 = {
        'CAM_ID_ARR': this.getCamIds,
        'objParentJson': gridChkDataObj,
        'ASP_NEWCUST_KEY': this.aspNewcustKey,
      };
      this.showDialog("M110402P04");
    },
    checkNum(item){
      let value = item.DIV_EDIT_COL;
      if(value == undefined){
        return;
      }
      let i = item.ROW_NUMBER - 1;
      value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      value = Number(value);

      if(value > 99){
        value = 99;
      }
      this.gridDataText2[i].DIV_EDIT_COL = value;
    },
    changeDivEditCol(item){
      if(this.gridSelectList2.length < 1){
        this.gridSelectList2.push(item);
      }else{
        let cnt = 0;
        for( let i in this.gridSelectList2 ){
          if(this.gridSelectList2[i].ROW_NUMBER === item.ROW_NUMBER){
            cnt++;
            break;
          }
        }
        if(cnt == 0){
          this.gridSelectList2.push(item);
        }
      }
    },
    showDialog(type) {
      this[`dialog${type}`] = true
    },
    hideDialog(type) {
      this[`dialog${type}`] = false
    },
    submitDialog(type) {
      this[`dialog${type}`] = false
    },
    async hideAndGetList(type){
      this[`dialog${type}`] = false;
      await this.getList();
    },
    inputZero(str){
      let reStr = str;
      if( typeof(str) === "undefined" || str == null || str == "" ){
        reStr = 0;
      }
      return reStr;
    },
    rowClick (item, row) {
      row.select(true);
    },
    rowSelect(idx) {
      this.selectedRow = idx;
    },
    isActiveRow(item){
      const activeClass = item === this.selectedRow ? 'active' : '';
      return activeClass;
    },
  },
  computed: {
    ...mapGetters({
      geUserRole: 'userStore/GE_USER_ROLE',
      geUserCompany: 'userStore/GE_USER_COMPANY'
    }),
    objList(){
      let list = [];
      for (let i in this.param){
        list.push(this.param[i]);
      }
      return list;
    },
    getCamIds(){
      let camIds = "";
      for(let i in this.objList){
        camIds += this.objList[i].CAM_ID + "/";
      }
      camIds = camIds.substr(0, camIds.length - 1);
      return camIds;
    },
    aspNewcustKey() {
      if(this.objList.length > 0){
        return this.objList[0].ASP_NEWCUST_KEY_DTAIL;
      }else{
        return "";
      }
    },
  },
};
</script>

<style></style>
