<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
     <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <!-- 사용자근태 -->
      <template>
        <div class="d-flex gray-box">
          <div>
            <div class="form-inp sm">
              <v-select
                v-model.trim="searchData.ASP_NEWCUST_KEY"
                :items="geUserCompany"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                disabled
                placeholder="선택"
              >
                <template v-slot:label>
                  회사구분
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-select>
            </div>
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              dark
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="searchData.BASE_DATE"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  readonly
                  label="등록일자"
                  class="form-inp sm icon-calendar ml-2"
                  append-icon="svg-calendar"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="searchData.BASE_DATE"
                color="#F7AFAE"
                no-title
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
                @input="menu1 = false"
                @change="selectRtn"
              ></v-date-picker>
            </v-menu>
            <div class="form-inp sm ml-2">
              <v-select
                v-model="searchData.USER_ATTR_A"
                :items="userAttrItemsA"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
              ></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                v-model="searchData.USER_ATTR_B"
                :items="userAttrItemsB"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
              ></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                v-model="searchData.USER_ATTR_C"
                :items="userAttrItemsC"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
              ></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                v-model="searchData.USER_ATTR_D"
                :items="userAttrItemsD"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
              ></v-select>
            </div>
          </div>
          <div class="ml-auto align-self-center mr-0">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" id="btnSearch" @click="selectRtn">조회</v-btn>
          </div>
        </div>
        <h2 class="tit-h2 d-flex mt-3">사용자근태 리스트</h2>
        <div class="mt-2">
          <v-data-table
            ref="gridUserAttendListRef"
            id="divGridDilceList"
            v-model="gridUserAttendList"
            :headers="gridUserAttendHeaders"
            :items="gridUserAttendItems"
            item-key="ROW_NUMBER"
            hide-default-footer
            dense
            height="460px"
            fixed-header
            class="grid-default"
            :single-select="false"
            :page.sync="page"
            @page-count="pageCount = $event"
            :items-per-page="30"
          >
          <template v-slot:[`item.ANNL_YN`]="{ item }">
            <td class="text-center">
              <v-simple-checkbox :ripple="false" :value="item.ANNL_YN.substring(1,2) === '1' ? true : false" @click="changeCheckbox(item, 'ANNL_YN')"></v-simple-checkbox>
            </td>
          </template>
          <template v-slot:[`item.HALFD_YN`]="{ item }">
            <td class="text-center">
              <v-simple-checkbox :ripple="false" :value="item.HALFD_YN.substring(1,2) === '1' ? true : false" @click="changeCheckbox(item, 'HALFD_YN')"></v-simple-checkbox>
            </td>
          </template>
          </v-data-table>
        </div>
      </template>
      <div class="grid-paging text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="totalVisible"
          next-icon="svg-paging-next"
          prev-icon="svg-paging-prev"
        ></v-pagination>
      </div>
      <p class="caption text-left">
        총 <strong class="clr-error">{{ gridUserAttendItems.length | inputNumberFormat }}</strong>건
      </p>
      <div class="text-right">
        <template>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnRegi')" outlined class="btn-point" id="btnSave" @click="processRtn">등록</v-btn>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { mixin } from "@/mixin/mixin.js"

export default {
  name: "MENU_M110402P01", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  props: {
    params:{
      type: Object,
      default: () => ({})
    },
    headerTitle: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      menu1: false,
      userAttrItemsA: [],
      userAttrItemsB: [],
      userAttrItemsC: [],
      userAttrItemsD: [],
      gridUserAttendList: [],
      gridUserAttendItems: [],
      gridUserAttendHeaders: [
        { text: 'NO', value: 'ROW_NUMBER', align: 'center', width: '70px', sortable: true },
        // { text: '사용자그룹ID', value: 'DEPT_CD', align: 'center', width: '30px', sortable: true },
        { text: '사용자그룹', value: 'DEPT_NM', align: 'center', width: '200px', sortable: true },
        // { text: '사용자ID', value: 'AGENT_ID ', align: 'center', width: '30px', sortable: true },
        { text: '사용자명', value: 'AGENT_NM', align: 'left', width: '200px', sortable: true },
        // { text: '연차YN', value: 'ANNL_YN_NM ', align: 'center', width: '30px', sortable: true },
        // { text: '반차YN', value: 'HALFD_YN_NM', align: 'center', width: '30px', sortable: true },
        { text: '연차', value: 'ANNL_YN', align: 'center', width: '70px', sortable: true },
        { text: '반차', value: 'HALFD_YN', align: 'center', width: '70px', sortable: true }
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      totalVisible: 10,
      searchVo: function() {
        this.ASP_NEWCUST_KEY=''
        this.BASE_DATE=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.USER_ATTR_A=''
        this.USER_ATTR_B=''
        this.USER_ATTR_C=''
        this.USER_ATTR_D=''
      },
      searchData: {}
    }
  },
  created() {
    this.searchData = new this.searchVo()
  },
  mounted() {
    this.initMain()
    this.selectRtn()
  },
  methods: {
    async initMain() {
      this.searchData = new this.searchVo()
      this.searchData.ASP_NEWCUST_KEY = this.params.ASP_NEWCUST_KEY
      this.gridUserAttendList = []
      this.gridUserAttendItems = []
      this.changeUserAttrItem()
    },
    async changeUserAttrItem(){
      const aspNewcustKey = this.searchData.ASP_NEWCUST_KEY
      this.searchData.USER_ATTR_A = ''
      this.searchData.USER_ATTR_B = ''
      this.searchData.USER_ATTR_C = ''
      this.searchData.USER_ATTR_D = ''
      this.userAttrItemsA = await this.mixin_attr_get(aspNewcustKey, 'A', '전체')
      this.userAttrItemsB = await this.mixin_attr_get(aspNewcustKey, 'B', '전체')
      this.userAttrItemsC = await this.mixin_attr_get(aspNewcustKey, 'C', '전체')
      this.userAttrItemsD = await this.mixin_attr_get(aspNewcustKey, 'D', '전체')
    },
    changeCheckbox(item, trgtNm) {
      let setVal = '10'
      let setNmVal = 'N'
      const annlYn = (!this.mixin_isEmpty(item.ANNL_YN)) && (item.ANNL_YN.substring(1,2) === '1')
      const halfdYn = (!this.mixin_isEmpty(item.HALFD_YN)) && (item.HALFD_YN.substring(1,2) === '1')
      if(trgtNm === 'ANNL_YN' && !annlYn && halfdYn) {
        setVal = '11'
        setNmVal = 'Y'
        this.$set(item, 'HALFD_YN', '10')
        this.$set(item, 'HALFD_YN_NM', 'N')
      }
      if(trgtNm === 'HALFD_YN' && annlYn && !halfdYn) {
        setVal = '11'
        setNmVal = 'Y'
        this.$set(item, 'ANNL_YN', '10')
        this.$set(item, 'ANNL_YN_NM', 'N')
      }
      if(!annlYn && !halfdYn) {
        setVal = '11'
        setNmVal = 'Y'
      }
      this.$set(item, trgtNm, setVal)
      this.$set(item, trgtNm+'_NM', setNmVal)
    },
    async processRtn() {
      if(this.mixin_isEmpty(this.searchData.BASE_DATE)) {
        this.common_alert(`등록일자는 필수값입니다.`, 'error')
        return false
      }
      const svrDate = await this.mixin_getSvrDate("YYYYMMDD")
      const todayNum = Number(svrDate)
      const baseDateNum = Number(this.searchData.BASE_DATE.replace(/[^0-9]/g, ''))
      if(todayNum > baseDateNum) {
        this.common_alert(`지난일자는 등록 할 수 없습니다.`, 'error')
        return false
      }
      this.common_confirm(`상담원근태를 등록 하시겠습니까?`, async ()=>{
        let reqData = {
          headers: {},
          sendData: {}
        }
        reqData.headers['SERVICE'] = 'phone.outbound.manage.agent-dclz-inqire-popup'
        reqData.headers['METHOD'] = 'regist'
        reqData.headers['TYPE'] = 'BIZ_SERVICE'
        reqData.headers['URL'] = '/api/phone/outbound/manage/agent-dclz-inqire-popup/regist'
        reqData.headers['ASYNC'] = false
        reqData.headers['GRID_ID'] = 'divGridDilceList'
        reqData.headers['ROW_CNT'] = 100
        reqData.headers['PAGES_CNT'] = 1
        let params = {}
        params['DATA_FLAG'] = 'U'
        params['BASE_DATE'] = this.searchData.BASE_DATE.replace(/[^0-9]/g, '')
        params['REG_MAN'] = this.geUserRole.userId
        params['CHNG_MAN'] = this.geUserRole.userId
        const gridId = this.$refs.gridUserAttendListRef.$attrs.id
        let gridData = JSON.parse(JSON.stringify(this.gridUserAttendItems))
        gridData.forEach((v,i) => {
          v['ANNL_YN'] = ((!this.mixin_isEmpty(v.ANNL_YN)) && (v.ANNL_YN.substring(1,2) === '1')) ? 'Y' : 'N'
          v['HALFD_YN'] = ((!this.mixin_isEmpty(v.HALFD_YN)) && (v.HALFD_YN.substring(1,2) === '1')) ? 'Y' : 'N'
          v[gridId+'_ROWNUM'] = i+1
          v['DATA_FLAG'] = 'N'
        })
        reqData.sendData = params
        if(gridData) {
          reqData.headers['DATA_OBJECT'] = 'OBND_DILCE_ARR'
          reqData.sendData['OBND_DILCE_ARR'] = gridData
        }
        let res = await this.common_postCall(reqData)
        if(!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
          this.common_alert(`정상적으로 수정되었습니다.`, 'done')
          this.selectRtn()
        }else {
          this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
        }
      })
    },
    async selectRtn() {
      this.gridUserAttendList = []
      this.gridUserAttendItems = []
      let reqData = {
        headers: {},
        sendData: {}
      }
      reqData.headers['SERVICE'] = 'phone.outbound.manage.agent-dclz-inqire-popup'
      reqData.headers['METHOD'] = 'inqire'
      reqData.headers['GRID_ID'] = 'divGridDilceList'
      reqData.headers['ASYNC'] = false
      reqData.headers['TYPE'] = 'BIZ_SERVICE'
      reqData.headers['URL'] = '/api/phone/outbound/manage/agent-dclz-inqire-popup/inqire'
      reqData.headers['ROW_CNT'] = 100
      reqData.headers['PAGES_CNT'] = 1
      let params = JSON.parse(JSON.stringify(this.searchData))
      for(let d in params) {
        if(d.indexOf('_DATE')>-1) {
          params[d] = params[d].replace(/[^0-9]/g, '')
        }
      }
      reqData.sendData = params
      const res = await this.common_postCall(reqData)
      if(!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
        if(!this.mixin_isEmpty(res.DATA) && res.DATA.length > 0) {
          for(let d of res.DATA) {
            for(let k of Object.keys(d)) {
              d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
            }
            d['ROW_NUMBER'] = this.gridUserAttendItems.length + 1
            this.gridUserAttendItems.push(d)
          }
        }
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }
    }
  },
  filters: {
    inputNumberFormat(v){
      return String(v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  computed: {
    ...mapGetters({
      geUserRole: 'userStore/GE_USER_ROLE',
      geUserCompany: 'userStore/GE_USER_COMPANY'
    }),
  },
}
</script>

<style></style>
